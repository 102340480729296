import { ApiServiceBase } from '~/api/services/ApiServiceBase'
import type { ApiPaginatedResponse, ApiResponse } from '~/api/types/Api'
import type { Staff, StaffRoleRequest } from '~/api/types/Staff'
import type { FetchOptions } from 'ofetch'

export default class StaffService extends ApiServiceBase {
  async all(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<Staff[]>> {
    return await this.call('/staff/v1/staff', options)
  }

  async retrieve(id: number | string, options?: FetchOptions<'json'>): Promise<ApiResponse<Staff>> {
    return await this.call(`/staff/v1/staff/${id}`, options)
  }

  async updateUserRole(id: string | number, data: StaffRoleRequest): Promise<ApiResponse<Staff>> {
    return await this.call(`/staff/v1/staff/${id}`, {
      method: 'put',
      body: data,
    })
  }

  async delete(id: number | string, options?: FetchOptions<'json'>): Promise<void> {
    return await this.call(`/staff/v1/staff/${id}`, {
      method: 'delete',
      ...options,
    })
  }

  async restore(id: number | string, options?: FetchOptions<'json'>): Promise<void> {
    return await this.call(`/staff/v1/staff/${id}/restore`, {
      method: 'post',
      ...options,
    })
  }
}
